import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { dateFilterOperators, filterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { Chip, Tooltip, Typography, Zoom } from '@mui/material';
import { Check, PriorityHigh } from '@mui/icons-material';
import React from 'react';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';
import { booleanCell } from '../cells/boolean-cell';
import FactCheck from '@mui/icons-material/FactCheck';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Link } from 'react-router-dom';

export const InventoryCheckAlternativeColumns = (
  handleValidation: (id: number) => void,
  handleArchive: (id: number) => void,
  handleDelete: (id: number) => void
): GridColumns => {
  const translationPrefix = 'entities.stock_quantity';
  const { t } = useTranslation();
  return [
    {
      field: 'id',
      headerName: t(`global.id`),
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <Link to={`${params.value}`}>
              <b className="text-secondary-500">{params.value}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'created_by',
      headerName: t(`global.created_by`),
      width: 150,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'date',
      headerName: t(`global.date`),
      width: 150,
      filterOperators: dateFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'notes',
      headerName: t(`global.description`),
      width: 350,
      filterOperators,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 350,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'partial',
      headerName: t(`global.partial`),
      width: 100,
      type: 'boolean',
      renderCell: booleanCell(t)
    },
    {
      field: 'criterion',
      headerName: t(`global.criterion`),
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (params.value && t(`global.${params.value.toLowerCase()}`)) ?? '';
      }
    },
    {
      field: 'suppliers',
      headerName: t(`global.suppliers`),
      sortable: false,
      filterable: false,
      width: 200,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: baseCell
    },
    {
      field: 'locations',
      headerName: t(`global.locations`),
      sortable: false,
      filterable: false,
      width: 200,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: baseCell
    },
    {
      field: 'categories',
      headerName: t(`global.categories`),
      sortable: false,
      filterable: false,
      width: 200,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: baseCell
    },
    {
      field: 'is_valid',
      headerName: t(`global.status`),
      width: 100,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams<string>) =>
        !params.row.open && (
          <Tooltip
            TransitionComponent={Zoom}
            arrow
            title={
              !params.row.is_valid &&
              params.row.errors.length > 0 && (
                <React.Fragment>
                  <Typography fontSize={12} color="inherit">
                    <ul>
                      {params.row.errors.map((error, i) => (
                        <li key={i}>- {error}</li>
                      ))}
                    </ul>
                  </Typography>
                </React.Fragment>
              )
            }>
            <Chip
              sx={{ background: 'transparent' }}
              icon={params.value ? <Check color="success" /> : <PriorityHigh color="warning" />}
            />
          </Tooltip>
        )
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [
          <GridActionsCellItem
            key={1}
            label="Validate"
            icon={<FactCheck />}
            onClick={() => {
              handleValidation(params.row.id);
            }}
          />,
          <GridActionsCellItem
            key={2}
            label="Archive"
            icon={<ArchiveIcon color="warning" />}
            onClick={() => {
              handleArchive(params.row.id);
            }}
          />,
          <ConfirmationDeleteTableDialog key={3} id={params.row.id} handleDelete={handleDelete} />
        ];
        return actions;
      },
      filterable: false
    }
  ];
};
