import { GridColumns, GridRowParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { dateCell } from 'components/common/enhanced/cells/date-cell';
import { Button } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { baseCell } from 'components/common/enhanced/cells/base-cell';

export function TransportDocumentUniqueFieldsColumns(
  handleUpdate?: (row: any) => void
): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        // Ensure that handleUpdate is defined before calling it
        const handleClick = () => {
          if (handleUpdate) {
            handleUpdate(params.row);
          }
        };

        return [
          <Button
            key="select-button"
            onClick={handleClick}
            variant="contained" // Optional, can be 'text', 'outlined', etc.
            color="primary">
            {t('global.select')} {/* Translated "SELECT" */}
          </Button>
        ];
      }
    },
    {
      field: 'ref',
      headerName: t(`global.document`),
      width: 125,
      filterable: false,
      sortable: false,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      type: 'text',
      flex: 200,
      filterable: false,
      sortable: false,
      renderCell: baseCell
    },
    {
      field: 'order',
      headerName: t(`global.order`),
      width: 150,
      type: 'text',
      filterable: false,
      sortable: false,
      renderCell: baseCell
    },
    {
      field: 'date',
      headerName: t(`global.date`),
      width: 100,
      type: 'date',
      filterable: false,
      sortable: false,
      renderCell: dateCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 200,
      type: 'string',
      filterable: false,
      sortable: false,
      renderCell: baseCell
    }
  ];
  return columns;
}
