import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface DDTLineProps {
  handleSetTransportDocuments: (ref) => void; // questo ANY è da cambiare con
}
function TransportDocumentRefLineForm(params: DDTLineProps) {
  const { t } = useTranslation();
  const { handleSetTransportDocuments } = params;

  return (
    <>
      <div className="flex justify-around">
        <ReactFinalForm hideControls onSubmit={handleSetTransportDocuments} formId="refLineUpdate">
          <div className="w-full p-2">
            <IdentityField
              name="ref"
              component={TextFieldAdapter}
              label={t('global.ref')}
              type="text"
              required
            />
          </div>
        </ReactFinalForm>
        <div className="p-4">
          <Button variant="contained" form="refLineUpdate" type="submit">
            <ArrowForwardIcon />
          </Button>
        </div>
      </div>
    </>
  );
}

export default TransportDocumentRefLineForm;
