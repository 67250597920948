import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { inventoryCheckService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { DataGridPremiumProps, GridColDef, GridColumns } from '@mui/x-data-grid-premium';
import { InventoryCheckColumns } from 'components/common/enhanced/common-headers';
import InventoryCheckDetail from './detail/InventoryCheckDetail';

import { Button, ButtonGroup } from '@mui/material';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { InventoryCheckAlternativeColumns } from 'components/common/enhanced/common-headers/inventory-check-alternative';
import toast from 'features/toast';
import { loadingActions } from 'features';
import { set } from 'lodash';

export const InventoryCheck = () => {
  const translationPrefix = 'pages.inventory-check';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const navigate = useNavigate();

  const [message, setMessage] = useState('');

  const handleInventoryCheckServiceValidation = (id: number) => {
    setMessage(t(`${translationPrefix}.message`));
    loadingActions.startLoading();
    inventoryCheckService.validate(id.toString()).then(() => {
      toast.info(t(`${translationPrefix}.validation-started`));
    });
    loadingActions.stopLoading();
  };

  const handleInventoryCheckServiceArchive = (id: number) => {
    inventoryCheckService.archive(id.toString()).then(() => {
      toast.success(t('actions.successfully.archived'));
      datagridRefresh.current();
    });
  };

  const handleInventoryCheckServiceDelete = (id: number) => {
    inventoryCheckService
      .delete(id)
      .then(() => {
        toast.success(t('actions.successfully.deleted'));
        datagridRefresh.current();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const aggregatedColumns: GridColumns = InventoryCheckAlternativeColumns(
    handleInventoryCheckServiceValidation,
    handleInventoryCheckServiceArchive,
    handleInventoryCheckServiceDelete
  );

  const columns: GridColDef[] = InventoryCheckColumns();

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <InventoryCheckDetail row={{ ...row }} columns={columns} />, [columns]);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <Button size="small" onClick={() => navigate('new')}>
                <AddIcon />
                {t(`${translationPrefix}.add`)}
              </Button>
            </ButtonGroup>
            <IsTenantAdministrator>
              <ButtonGroup>
                <Link to="archive">
                  <Button variant="outlined" size="small" color="warning">
                    {t(`global.archive`)}
                  </Button>
                </Link>
              </ButtonGroup>
            </IsTenantAdministrator>
          </div>
        }>
        <EnhancedDataGrid
          columns={aggregatedColumns}
          service={inventoryCheckService}
          refresh={datagridRefresh}
          getDetailPanelContent={getDetailPanelContent}
          disableDelete
        />
      </TenantPage>
    </>
  );
};

export default InventoryCheck;
