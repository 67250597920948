import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  DialogContentText,
  IconButton
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { DataGridPremium, GridActionsCellItem, GridColumns } from '@mui/x-data-grid-premium';
import TransportDocumentLineForm from '../TransportDocumentRefLineForm';
import { TransportDocumentUniqueFieldsColumns } from '../TransportDocumentUniqueFieldsColumns';
import { transportDocumentLineService, transportDocumentService } from 'services/api';
import toast from 'features/toast';
import CloseIcon from '@mui/icons-material/Close';

interface UpdateDDTProps {
  ddtLineId?: number;
  supplierId?: number;
  warehouseId?: number;
  onClose?: () => void;
}

export default function TransportDocumentLineUpdateDialog(params: UpdateDDTProps) {
  const { ddtLineId, supplierId, warehouseId, onClose } = params;
  const translatePrefix = 'entities.ddt.edit-ddt';
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [rows, setRows] = useState<Record<string, unknown>[]>([]);

  /**
   * Opens the confirmation dialog for deleting a row.
   *
   * @param {number} id - The ID of the row to be deleted.
   */
  const handleOpenDialog = () => {
    // Set the selected row ID and open the dialog.
    setOpenDialog(true);
  };

  /**
   * Closes the confirmation dialog.
   */
  const handleCloseDialog = () => {
    // Close the dialog and reset the selected row ID.
    setOpenDialog(false);
  };

  const handleUpdateDDT = (row: any) => {
    transportDocumentLineService.update(ddtLineId, { document: row.id }).then(() => {
      toast.success(t(translatePrefix + '.success', { ddt_ref: row.ref }));
      // Close the dialog
      setOpenDialog(false);
      // Re Render the parent's table
      onClose();
    });
  };

  // colonne enhanchedDataGrid
  const columns: GridColumns = TransportDocumentUniqueFieldsColumns(handleUpdateDDT);

  return (
    <>
      <GridActionsCellItem
        label={t(translatePrefix + 'label')}
        icon={<DoubleArrowIcon />}
        onClick={() => {
          handleOpenDialog();
        }}
      />
      <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px' // Adjust padding as needed
          }}>
          {t(translatePrefix + '.title')}
          <DialogActions>
            <IconButton onClick={handleCloseDialog} color="primary">
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{t(translatePrefix + '.message-ddt')}</DialogContentText>
          <br />
          <TransportDocumentLineForm
            handleSetTransportDocuments={(values) => {
              transportDocumentService
                .getOnlyBaseFields(0, 0, {
                  ref__startswith: values?.ref,
                  supplier_id: supplierId,
                  warehouse_id: warehouseId
                })
                .then((res) => {
                  if (res.count > 0) {
                    setRows(res.results);
                  } else {
                    toast.error(t(translatePrefix + '.wrong-ddt'));
                  }
                });
            }}
          />
          <br />
          <hr />
          <br />
          <DataGridPremium columns={columns} rows={rows} autoHeight />
        </DialogContent>
      </Dialog>
    </>
  );
}
