import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-premium';
import { Button } from '@mui/material';
import { SaveAlt as SaveAltIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface ExportProps {
  purchaseRequestId?: number | string;
}

function ExportButtonToolbar(props: ExportProps) {
  const { purchaseRequestId } = props;
  const { t } = useTranslation();

  const apiRef = useGridApiContext(); // Ottieni il riferimento all'API della griglia

  const handleExportExcel = () => {
    const visibleColumns = apiRef.current.getVisibleColumns();
    const filteredColumns = visibleColumns.filter((col) => col.field !== 'actions');

    apiRef.current.exportDataAsExcel({
      fileName: `dettagli_movimento_${purchaseRequestId}`,
      fields: filteredColumns.map((col) => col.field)
    });
  };

  return (
    <div className="p-2">
      <GridToolbarContainer>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveAltIcon />}
          onClick={handleExportExcel} // Chiama l'esportazione Excel
        >
          {t('global.export')}
        </Button>
      </GridToolbarContainer>
    </div>
  );
}

export default ExportButtonToolbar;
