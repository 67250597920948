import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { contractEquipmentService, maintenanceService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import {
  DataGridPremiumProps,
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { filterOperators } from '../../../constants';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { MaintenanceColumns } from 'components/common/enhanced/common-headers';
import MaintenanceDetail from './detail/MaintenanceDetail';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link, useSearchParams } from 'react-router-dom';
import CloseMaintenanceForm from './CloseMaintenanceForm';
import dayjs from 'dayjs';
import MultipleDatesPicker from '@ambiot/material-ui-multiple-dates-picker';

export const Maintenance = () => {
  const translationPrefix = 'pages.maintenance';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const [open, setOpen] = useState(false);
  const [maintenanceId, setMaintenanceId] = useState<number | null>(null);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [contractEquipment, setContractEquipment] = useState<number | null>(null);
  const [searchParams] = useSearchParams();

  const onOpenCalendar = (contractEquipmentId: number) => {
    setContractEquipment(contractEquipmentId);
    setSelectedDates([]);
    setOpenCalendar(true);
  };

  const onCancel = () => {
    setSelectedDates([]);
    setOpenCalendar(false);
    setContractEquipment(null);
  };

  const onSubmit = (dates) => {
    setSelectedDates(dates);
    const formattedDates = dates.map((date) => dayjs(date).format('YYYY-MM-DD'));
    contractEquipmentService.addMaintenanceDates(contractEquipment, formattedDates).then(() => {
      datagridRefresh.current();
      onCancel();
    });
  };

  const aggregatedColumns: GridColumns = [
    {
      field: 'equipment',
      headerName: t(`global.equipment`),
      width: 250,
      filterOperators,
      valueGetter: (params) => params.row.equipment_code,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 300,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'serial_number',
      headerName: t(`entities.equipment.serial_number`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [
          <GridActionsCellItem
            key={1}
            label="Calendario"
            icon={<ScheduleIcon />}
            onClick={() => onOpenCalendar(params.row.contract_equipment_id)}
          />
        ];
        return actions;
      },
      filterable: false
    }
  ];

  const setOpenDialog = (maintenanceId: number) => {
    setOpen(true);
    setMaintenanceId(maintenanceId);
  };

  const setCloseDialog = () => {
    setOpen(false);
    setMaintenanceId(null);
  };

  const closeMaintenance = (values) => {
    maintenanceService.closeMaintenance(+maintenanceId, values).then(() => {
      datagridRefresh.current();
    });
    setCloseDialog();
  };

  const columns: GridColDef[] = MaintenanceColumns(setOpenDialog);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <MaintenanceDetail row={{ ...row }} columns={columns} />, [columns]);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <IsTenantAdministrator>
                <Link to="archive">
                  <Button variant="outlined" size="small">
                    {t(`global.archive`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
          </div>
        }>
        <EnhancedDataGrid
          columns={aggregatedColumns}
          service={contractEquipmentService}
          getMethod={contractEquipmentService.aggregatedContract}
          initialParams={{ equipment_id: searchParams.get('equipment_id') }}
          refresh={datagridRefresh}
          getDetailPanelContent={getDetailPanelContent}
          disableDelete
        />
        <Dialog open={open} maxWidth="lg" fullWidth>
          <DialogTitle>{t(`${translationPrefix}.close`)}</DialogTitle>
          <DialogContent>
            <CloseMaintenanceForm onSubmit={closeMaintenance} />
          </DialogContent>
          <DialogActions>
            <Button onClick={setCloseDialog} color="error">
              {t('actions.cancel')}
            </Button>
            <Button type="submit" form="closeMaintenance">
              {t(`actions.confirm`)}
            </Button>
          </DialogActions>
        </Dialog>
      </TenantPage>
      <MultipleDatesPicker
        open={openCalendar}
        selectedDates={selectedDates}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default Maintenance;
