import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import FileUploader from 'components/common/file-uploader';
import SectionTitle from 'components/common/SectionTitle';
import { currencyFormatter } from 'helpers/currency-formatter';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { transportDocumentService } from 'services/api';
import {
  booleanFilterOperators,
  dateFilterOperators,
  filterOperators,
  numberFilterOperators
} from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';
import { IPurchaseOrder } from 'interfaces';
import { booleanValueFormatter } from '../data-grid/utils';
import { currencyCell } from '../cells/currency-cell';
import { dateCell } from '../cells/date-cell';
import ConfirmationUnlinkTableDialog from 'components/helpers/ConfirmationGridUnlink';

export function TransportDocumentColumns(
  isLinkable: boolean,
  onUploadSuccess: any,
  unplugDDTfromInvoice?: (id) => void
): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'ref',
      headerName: t(`entities.ddt.ref`),
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) =>
        isLinkable ? (
          <NavLink to={`./${params.row?.id}`}>
            <SectionTitle>{params.value}</SectionTitle>
          </NavLink>
        ) : (
          <SectionTitle>{params.value}</SectionTitle>
        )
    },
    {
      field: 'closed',
      headerName: t(`entities.ddt.closed`),
      width: 100,
      filterOperators: booleanFilterOperators,
      renderCell: booleanCell(t),
      editable: true,
      type: 'boolean'
    },
    {
      field: 'billable',
      headerName: t(`entities.ddt.billable`),
      width: 100,
      filterOperators: booleanFilterOperators,
      editable: true,
      type: 'boolean',
      renderCell: booleanCell(t)
    },
    {
      field: 'date',
      headerName: t(`entities.ddt.date`),
      filterOperators: dateFilterOperators,
      width: 150,
      renderCell: dateCell
    },
    {
      field: 'invoice_ref',
      headerName: t(`entities.ddt.invoice_ref`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'invoice_date',
      headerName: t(`entities.ddt.invoice_date`),
      filterOperators: dateFilterOperators,
      width: 150,
      renderCell: dateCell
    },
    {
      field: 'credit_memo_amount',
      headerName: t(`entities.ddt.credit_memo_amount`),
      filterOperators: numberFilterOperators,
      width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: currencyCell
    },
    {
      field: 'order',
      headerName: t(`entities.ddt.order`),
      filterOperators,
      width: 150,
      renderCell: (params: GridRenderCellParams<IPurchaseOrder>) => {
        const order = params.value;
        return (
          order &&
          (isLinkable ? (
            <NavLink to={`/tenant/orders/delivery?ref=${order.ref}`}>
              <SectionTitle>{order.ref}</SectionTitle>
            </NavLink>
          ) : (
            <SectionTitle>{order.ref}</SectionTitle>
          ))
        );
      }
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    }
  ];

  ['amount', 'expected_amount', 'shipping_fare'].forEach((colName) => {
    columns.push({
      field: colName,
      headerName: t(`entities.ddt.${colName}`),
      filterOperators,
      type: 'number',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={currencyFormatter(params.value)} />
      )
    });
  });

  if (onUploadSuccess) {
    ['invoice_file', 'ddt_file', 'invoice_revision_file'].forEach((file) => {
      columns.push({
        field: file,
        headerName: t(`entities.ddt.${file}`),
        filterOperators,
        filterable: false,
        sortable: false,
        width: 150,
        valueFormatter: booleanValueFormatter,
        renderCell: (params: GridRenderCellParams<string>) => (
          <FileUploader
            onUploadSuccess={onUploadSuccess}
            service={transportDocumentService}
            entityId={params.row?.id}
            attachmentName={file}
            filename={params.value}
          />
        )
      });
    });
  }

  if (unplugDDTfromInvoice) {
    columns.push({
      field: 'actions',
      headerName: t(`global.actions`),
      filterOperators,
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params: GridRenderCellParams<string>) => (
        <ConfirmationUnlinkTableDialog id={params.row?.id} handleUnlink={unplugDDTfromInvoice} />
      )
    });
  }

  return columns;
}
