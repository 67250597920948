import {
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import { TenantPage } from 'components/common';
import FileUploader from 'components/common/file-uploader';
import SectionTitle from 'components/common/SectionTitle';
import EntityHistory from 'components/widgets/EntityHistory';
import toast from 'features/toast';
import { IEquipment } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { equipmentService } from 'services/api';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WorkstationForm from '../EquipmentForm';
import EquipmentToCostDialog from '../EquipmentToCostDialog';
import ConfirmationUnlinkButtonDialog from 'components/helpers/ConfirmationButtonUnlink';
import { DocumentTableCardComponent } from 'components/common/documentLM/DocumentTableCardComponent';

const downloadableFields = [
  'technical_file',
  'user_manual',
  'ce_ivd',
  'maintenance_checklist_template',
  'preemptive_maintenance_calendar'
];

export const EditEquipment = () => {
  const { t } = useTranslation();
  const translationPrefix = 'pages.equipment.edit';
  const { equipmentId } = useParams();

  const [equipment, setEquipment] = useState<IEquipment>();
  const [mode, setMode] = useState('');

  const fetchEquipment = () => {
    equipmentService.get(+equipmentId).then((res) => {
      setEquipment(undefined);
      setEquipment(res);
    });
  };

  const updateEquipment = async (values) => {
    equipmentService
      .update(equipment.id, {
        ...values,
        market_value: values['market_value'] && values['market_value'].replace(',', '.')
      })
      .then(() => {
        toast.success('Strumento aggiornato con successo');
      });
  };

  useEffect(() => {
    equipmentService.get(+equipmentId).then(setEquipment);
  }, []);

  const handleRemoveLink = (id: number, pars: string) => {
    equipmentService.removeCostLink(+equipmentId, { model: pars, object_id: id }).then(() => {
      fetchEquipment();
      toast.success(t(translationPrefix + '.price-origin-removed-success'));
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={equipment?.code}>
      {equipment && (
        <div className="flex flex-wrap">
          <WorkstationForm equipment={equipment} onSubmit={updateEquipment} />
          {/* Attachments */}
          <div className="py-4 w-full mb-4">
            <SectionTitle>{t(`global.attachments`)}</SectionTitle>
            <Card variant="outlined">
              <CardContent>
                <div className="flex flex-wrap">
                  {downloadableFields.map((field, i) => (
                    <div key={i} className={`w-1/5`}>
                      <span>{t(`entities.equipment.${field}`)}:</span>
                      <FileUploader
                        service={equipmentService}
                        entityId={equipment.id}
                        attachmentName={field}
                        filename={equipment[field]}
                      />
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="w-full p-4">
            <Card variant="outlined">
              <DocumentTableCardComponent
                originalService={'equipment'}
                objectId={equipment.id}
                dictParams={{ equipment: equipmentId }}
                folder={'menu.equipments/' + equipment.code}
                folder_description={`ID: ${equipment.id}; Descrizione: ${equipment.description}; Seriale: ${equipment.serial_number}; Codice: ${equipment.code}`}
              />
            </Card>
          </div>

          {/* <IsSuperAdmin> */}
          <div className="w-full p-4 flex flex-wrap">
            <div className="w-full">
              <SectionTitle>{t('global.costs')}</SectionTitle>
            </div>
            {equipment &&
              [
                { field: 'laboratories', link: '/tenant/topology/lab/' },
                { field: 'operationalunits', link: '/tenant/topology/operationalunit/' },
                { field: 'sectors', link: '/tenant/topology/sector/' },
                { field: 'workstations', link: '/tenant/cost-analysis/workstations/' },
                { field: 'exams', link: '/tenant/cost-analysis/exams/' }
              ].map((e) => (
                <div
                  key={e.field}
                  className="w-full sm:w-1/2 lg:w-1/5 pl-4 first:pl-0 border-r last:border-0">
                  <div className="flex justify-start items-center mt-4 mb-2">
                    <Typography variant="body1" component="div" fontWeight={700}>
                      {t(`global.${e.field}`)}
                    </Typography>
                    <IconButton color="primary" onClick={() => setMode(e.field)}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </div>
                  <List dense={true} className="ml-0">
                    {equipment[e.field] &&
                      equipment[e.field].map((entity, i) => (
                        <ListItem
                          key={i}
                          sx={{ padding: 0 }}
                          secondaryAction={
                            <div key={i}>
                              <NavLink to={`${e.link}${entity.id}`}>
                                <IconButton edge="end">
                                  <VisibilityIcon />
                                </IconButton>
                              </NavLink>
                              <ConfirmationUnlinkButtonDialog
                                handleUnlink={handleRemoveLink}
                                id={entity.id}
                                pars={e.field}
                                iconButton={true}
                              />
                            </div>
                          }>
                          <ListItemButton>
                            <ListItemText primary={entity.code} secondary={entity['description']} />
                            <Divider variant="inset" component="li" />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </div>
              ))}
          </div>
          {
            <EquipmentToCostDialog
              equipmentId={+equipmentId}
              mode={mode}
              setMode={setMode}
              callback={fetchEquipment}
            />
          }
          {/* History */}
          <div className="w-full md:w-1/2 pr-4">
            <Card variant="outlined">
              <CardContent>
                <EntityHistory service={equipmentService} entityId={equipment.id} />
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </TenantPage>
  );
};

export default EditEquipment;
