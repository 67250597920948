import { TenantPage } from 'components/common';
import { TransportDocumentColumns } from 'components/common/enhanced/common-headers/transport-document';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { transportDocumentService } from 'services/api';
import { uniqueId } from 'lodash';
import { Button, ButtonGroup } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import AddIcon from '@mui/icons-material/Add';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { PurgeButton } from 'components/helpers/PurgeButton';

export function TransportDocumentList() {
  const { t } = useTranslation();
  const prefix = 'pages.ddt.list';

  const navigate = useNavigate();
  const datagridRefresh = useRef(null);

  const columns = TransportDocumentColumns(true, datagridRefresh.current);
  const filters = [];
  const initialParams = {};
  filters.push({
    id: uniqueId(),
    columnField: 'closed',
    operatorValue: 'is',
    value: 'false'
  });
  initialParams['closed'] = 'false';

  return (
    <TenantPage
      title={t(`${prefix}.title`)}
      subtitle={t(`${prefix}.subtitle`)}
      menuRight={
        <ButtonGroup size="small">
          <NavLink to="invoiced-movements">
            <Button>{t(`${prefix}.invoiced-movements`)}</Button>
          </NavLink>
          <IsTenantAdministrator>
            <Button size="small" onClick={() => navigate('new')}>
              <AddIcon />
              {t(`${prefix}.add`)}
            </Button>
          </IsTenantAdministrator>
          <IsSuperAdmin>
            <PurgeButton service={transportDocumentService} refresh={datagridRefresh} />
          </IsSuperAdmin>
        </ButtonGroup>
      }>
      <EnhancedDataGrid
        columns={columns}
        initialFilterItems={filters}
        initialParams={initialParams}
        service={transportDocumentService}
        refresh={datagridRefresh}></EnhancedDataGrid>
    </TenantPage>
  );
}

export default TransportDocumentList;
