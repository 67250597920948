import {
  DataGridPremium,
  GridColDef,
  GridColumns,
  GridFilterItem,
  GridFilterModel,
  GridLinkOperator,
  GridRowParams,
  itIT,
  useGridApiRef,
  GridSortModel
} from '@mui/x-data-grid-premium';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN } from './../../../../constants';
import { getPaginationDefault, setPaginationDefault } from 'features/paginationHelper';

import { BaseService } from 'services/api/BaseService';
import { EnhancedToolbarProvider } from './EnhancedToolbarProvider';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { onEditCellCallbackProvider } from './utils';
import toast from 'features/toast';
import { useAppSelector } from 'app/store';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';

interface EnhancedDataGridProps {
  service: BaseService<any>;
  columns: GridColDef[] | GridColumns;
  getMethod?: (pageSize: number, offset: number, params: Record<string, string>) => Promise<any>;
  initialParams?: Record<string, string>;
  refresh?: MutableRefObject<any>;
  rows?: MutableRefObject<any>;
  outerParams?: any;
  fullTextQuery?: string;
  clientSide?: boolean;
  initialFilterItems?: GridFilterItem[];
  initialOptions?: Partial<GridInitialStatePremium>;
  dense?: boolean;
  checkboxSelection?: boolean;
  disableMultipleSelection?: boolean;
  selectionModelChange?: any;
  getDetailPanelContent?: any;
  disableDelete?: boolean;
}

function EnhancedDataGrid(props: EnhancedDataGridProps) {
  const {
    service,
    columns,
    getMethod,
    initialParams,
    refresh,
    rows,
    outerParams,
    fullTextQuery,
    clientSide,
    initialFilterItems,
    initialOptions,
    dense,
    checkboxSelection,
    disableMultipleSelection,
    selectionModelChange,
    getDetailPanelContent,
    disableDelete
  } = props;

  const { t } = useTranslation();

  const groups = useAppSelector((state) => state.auth.groups);
  const startingPageSize = getPaginationDefault();
  const apiRef = useGridApiRef();

  const [skip, setSkip] = useState(false);
  const [pageSize, setPageSize] = useState(startingPageSize || 10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [items, setItems] = useState<any[]>([]);
  const [orderMacro, setOrderMacro] = useState('');
  const [filter, setFilter] = useState<Record<string, string>>(null);

  const defaultWarehouse = useAppSelector((state) => state.auth.warehouse);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: []
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [preferenceKey, setPreferenceKey] = useState('');

  /**
   * DataGrid visualization preferences.
   * Save settings to local storage given a `preferenceKey`
   * and apply them on load.
   */

  // Apply saved settings
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    if (firstRender) {
      // if it's the first render, change the state to trigger the useState
      setFirstRender(false);
    } else {
      refreshColumnsData();
    }
  }, [
    pageSize,
    page,
    fullTextQuery,
    orderMacro,
    filter,
    outerParams,
    defaultWarehouse,
    filterModel.items,
    sortModel
  ]);
  useEffect(() => {
    if (firstRender === false) {
      // first render -> restore the state and trigger refresh
      const pk = btoa(
        JSON.stringify({
          service: service.modulePrefix,
          getMethod,
          columns: columns.map((col) => col.field)
        })
      );
      setPreferenceKey(pk);
      const datagridState = localStorage.getItem(pk);
      if (datagridState) {
        apiRef.current.restoreState(JSON.parse(datagridState));
      } else {
        refreshColumnsData();
      }
    }
  }, [firstRender]);

  // Export current state to local storage
  const exportState = useCallback(() => {
    if (apiRef && preferenceKey) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem(
        preferenceKey,
        JSON.stringify({
          columns: currentState.columns,
          filter: currentState.filter,
          sorting: currentState.sorting
        })
      );
    }
  }, [apiRef, preferenceKey]);

  // Save applied filters
  useEffect(() => {
    exportState();
  }, [filterModel.items, sortModel, exportState]);

  const refreshColumnsData = () => {
    if (!isLoading) {
      setIsLoading(true);
      let searchParams = {};
      filterModel.items.map((element) => {
        searchParams = { ...searchParams, ..._getFilterObj(element) };
      });

      if (!skip && initialParams) {
        searchParams = initialParams;
        setSkip(true);
      }

      if (!skip && initialFilterItems) {
        let filters = {};
        initialFilterItems.forEach((element) => {
          filters = { ...filters, ..._getFilterObj(element) };
        });
        searchParams = { ...searchParams, ...filters };
      }

      if (defaultWarehouse) {
        const warehouseColumn = columns.find((col) => col.field.includes('warehouse'));
        if (warehouseColumn) {
          searchParams = { ...searchParams, [warehouseColumn.field]: defaultWarehouse };
        }
      }
      if (fullTextQuery) {
        searchParams['q'] = fullTextQuery;
      }
      if (orderMacro.trim().length !== 0) {
        searchParams['ordering'] = orderMacro;
      }
      if (filter) {
        searchParams = { ...searchParams, ...filter };
      }
      if (outerParams) {
        searchParams = { ...searchParams, ...outerParams };
      }
      if (getMethod) {
        getMethod(pageSize, page * pageSize, searchParams)
          .then((res) => {
            const results = res.results;
            setItems(
              results.map((item) => {
                if (!item?.id) return { ...item, id: uuidv4() };
                return item;
              })
            );
            setCount(res.count);
            setIsLoading(false);
          })
          .catch(() => {
            searchParams = {};
            setIsLoading(false);
            setFilter(null);
            setPage(0);
          });
      } else {
        service
          .getAllPaginated(pageSize, page * pageSize, searchParams)
          .then((res) => {
            setItems(res.results);
            setCount(res.count);
            setIsLoading(false);
          })
          .catch(() => {
            searchParams = {};
            setIsLoading(false);
            setFilter(null);
            setPage(0);
          });
      }
    }
  };

  useEffect(() => {
    if (refresh) refresh.current = refreshColumnsData;
    const startingPageSize = getPaginationDefault();
    if (startingPageSize) setPageSize(startingPageSize);
  }, []);

  useEffect(() => {
    if (rows) rows.current = items;
  }, [items]);

  useEffect(() => {
    if (pageSize) {
      setPaginationDefault(pageSize);
    }
  }, [pageSize]);

  useEffect(() => {
    setPage(0);
  }, [fullTextQuery, filter, orderMacro]);

  const onSortingChange = (x) => {
    setSortModel(x);
    if (x.length > 0) setOrderMacro(`${x[0].sort === 'desc' ? '-' : ''}${x[0].field}`);
    else setOrderMacro('');
  };

  const onFilterChange = (x) => {
    setFilterModel(x);
    let filters = {};
    if (!skip && initialParams) filters = initialParams;
    x.items.map((element) => {
      filters = { ...filters, ..._getFilterObj(element) };
    });
    setFilter(filters);
    setSkip(true);
  };

  const handleOnDelete = (id: number) => {
    service.delete(id).then(() => {
      refreshColumnsData();
      toast.success(t('global.delete.successfully'));
    });
  };

  function _getFilterObj(item): Record<string, string> {
    if (['contains', '=', 'is'].includes(item.operatorValue) && item.value?.trim()?.length > 0) {
      return { [item.columnField]: item.value };
    } else if (['equals'].includes(item.operatorValue) && item.value?.trim()?.length > 0) {
      return { [`${item.columnField}`]: item.value, [`${item.columnField}__exact`]: true };
    } else if (['>', 'after'].includes(item.operatorValue) && item.value?.trim()?.length > 0) {
      return { [`${item.columnField}__gt`]: item.value };
    } else if (['>=', 'onOrAfter'].includes(item.operatorValue) && item.value?.trim()?.length > 0) {
      return { [`${item.columnField}__gte`]: item.value };
    } else if (['<', 'before'].includes(item.operatorValue) && item.value?.trim()?.length > 0) {
      return { [`${item.columnField}__lt`]: item.value };
    } else if (
      ['<=', 'onOrBefore'].includes(item.operatorValue) &&
      item.value?.trim()?.length > 0
    ) {
      return { [`${item.columnField}__lte`]: item.value };
    } else {
      return null;
    }
  }

  const onEditCell = onEditCellCallbackProvider(service, refresh);

  const _columns = [...columns];

  if (
    !!service &&
    service.canDelete &&
    !disableDelete &&
    groups &&
    groups.some((group) => [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN].includes(group))
  ) {
    _columns.push({
      field: '',
      type: 'actions',
      // headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        return [
          <ConfirmationDeleteTableDialog
            key={999}
            id={params.row['id']}
            handleDelete={handleOnDelete}
          />
        ];
      },
      filterable: false
    });
  }

  return (
    <div style={{ width: '100%' }}>
      {/* Hide multi link operator input */}
      <style>{'.MuiDataGrid-filterFormLinkOperatorInput{display:none}'}</style>
      <DataGridPremium
        apiRef={apiRef}
        initialState={{
          filter: {
            filterModel: {
              items: [
                ...(initialFilterItems || []),
                ...(columns.find((col) => defaultWarehouse && col.field.includes('warehouse'))
                  ? [
                      {
                        columnField: columns.find((col) => col.field.includes('warehouse')).field,
                        operatorValue: 'contains',
                        value: defaultWarehouse
                      }
                    ]
                  : [])
              ],
              linkOperator: GridLinkOperator.And,
              quickFilterLogicOperator: GridLinkOperator.And
            }
          },
          pinnedColumns: {
            left: ['code', 'approved', 'moved_quantity', 'quantity'],
            right: ['actions']
          },
          ...(initialOptions || {})
        }}
        localeText={{
          ...itIT.components.MuiDataGrid.defaultProps.localeText,
          filterValueTrue: t('global.yes'),
          filterValueFalse: t('global.no')
        }}
        sx={{ border: 'none', height: '75vh' }}
        disableSelectionOnClick
        pagination
        density={dense ? 'compact' : 'standard'}
        columns={_columns}
        pageSize={pageSize}
        loading={isLoading}
        page={page}
        rows={items}
        onPageChange={(page) => {
          setPage(page);
        }}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000, 2000, 5000]}
        rowCount={count}
        paginationMode={clientSide ? 'client' : 'server'}
        sortingMode={clientSide ? 'client' : 'server'}
        filterMode={clientSide ? 'client' : 'server'}
        onSortModelChange={onSortingChange}
        onFilterModelChange={onFilterChange}
        onColumnOrderChange={exportState}
        onColumnWidthChange={exportState}
        onColumnVisibilityModelChange={exportState}
        sortModel={sortModel}
        // onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        filterModel={filterModel}
        // onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        components={{
          Toolbar: outerParams?.warehouse_id
            ? EnhancedToolbarProvider(service, +outerParams.warehouse_id, outerParams)
            : EnhancedToolbarProvider(service, null, outerParams)
        }}
        onCellEditCommit={onEditCell}
        checkboxSelection={checkboxSelection}
        disableMultipleSelection={disableMultipleSelection}
        onSelectionModelChange={selectionModelChange}
        autoHeight
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent ?? null}
      />
    </div>
  );
}

export default EnhancedDataGrid;
