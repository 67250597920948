import { useField } from 'react-final-form';
import { Checkbox, FormControlLabel } from '@mui/material';

export const CheckboxAdapter = ({
  input: { name, onChange, ...restInput },
  label,
  ...rest
}: any) => {
  const { input } = useField(name);

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...rest}
          name={name}
          onChange={onChange}
          checked={input.value || false}
          inputProps={restInput}
        />
      }
      label={label}
    />
  );
};
