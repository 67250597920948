import { CircularProgress, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { memo, useRef, useState } from 'react';
import { inventoryCheckLineService } from 'services/api';

const InventoryCheckDetail = ({ row: rowProp, columns: columns }: any) => {
  const params = {
    inventory_check: rowProp.id
  };

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (refresh && typeof refresh.current === 'function') {
  //     refresh.current();
  //   }
  //   if (rows.current.length > 0) setLoading(false);
  // }, [rows]);

  return (
    <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
      <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          {/* {!loading && ( */}
          <EnhancedDataGrid
            columns={columns}
            service={inventoryCheckLineService}
            outerParams={params}
          />
          {/* 
          )}
          {loading && (
            <Tooltip title={message}>
              <IconButton onClick={() => setLoading(false)}>
                <CircularProgress />
              </IconButton>
            </Tooltip>
          )} */}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default memo(InventoryCheckDetail);
