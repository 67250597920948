import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IInventoryCheck, IOption } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { categoryService, locationService, supplierService, warehouseService } from 'services/api';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';

interface InventoryCheckFormParams {
  inventory: IInventoryCheck;
  onSubmit: any;
}

const criteria = [
  { id: 1, code: 'supplier', description: 'Fornitore' },
  { id: 2, code: 'location', description: 'Piazza' },
  { id: 3, code: 'category', description: 'Categoria' }
];

function InventoryCheckForm(params: InventoryCheckFormParams) {
  const { inventory, onSubmit } = params;
  const { t } = useTranslation();

  const [partial, setPartial] = useState(inventory?.partial);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);
  const [criterion, setCriterion] = useState<IOption | null>(null);
  const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    if (inventory?.partial) setPartial(inventory.partial);
  }, [inventory?.partial]);

  useEffect(() => {
    // console.log(criterion);
    switch (criterion?.code) {
      case 'supplier':
        inventory.criterion = criterion.id;
        supplierService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: `${item.code} - ${item.company_name}`
              };
            })
          );
        });
        break;
      case 'location':
        inventory.criterion = criterion.id;
        locationService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: item.code
              };
            })
          );
        });
        break;
      case 'category':
        inventory.criterion = criterion.id;
        categoryService.getAllBaseInformation().then((res) => {
          setOptions(
            res.map((item) => {
              return {
                id: item.id,
                code: item.code
              };
            })
          );
        });
        break;
      default:
        setOptions([]);
    }
  }, [criterion?.id]);

  const handleSubmit = (values) => {
    switch (criterion?.code) {
      case 'supplier':
        delete values['location'];
        delete values['category'];
        break;
      case 'location':
        delete values['supplier'];
        delete values['category'];
        break;
      case 'category':
        delete values['supplier'];
        delete values['location'];
        break;
    }
    values['partial'] = partial;
    onSubmit(values);
  };

  useEffect(() => {
    warehouseService.getAllBaseInformation().then(setWarehouses);
  }, []);

  const togglePartial = () => {
    setPartial((prevState) => !prevState);
  };

  return (
    <div>
      <div className="text-primary-600 col-start-7 col-end-12 pb-2">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={partial}
                onChange={togglePartial}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography color="text-primary-600">
                <b>{t('global.partial-inventory')}</b>
              </Typography>
            }
          />
        </FormGroup>
      </div>
      {inventory && (
        <ReactFinalForm onSubmit={handleSubmit} edit={!!inventory.id}>
          <div className="flex flex-wrap">
            <div className="w-full mb-4 md:w-1/3 pr-4">
              <IdentityField
                name="warehouse"
                component={AutocompleteAdapter}
                initValue={warehouses.find((w) => w.code == inventory.warehouse)}
                options={warehouses}
                label={t('global.warehouse')}
              />
            </div>
            {partial && (
              <>
                <div className="w-full mb-4 md:w-1/3 pr-4">
                  <IdentityField
                    name="criterion"
                    component={AutocompleteAdapter}
                    initValue={criteria.find((o) => o.id == inventory?.criterion)}
                    options={criteria}
                    optionLabel="description"
                    label={t('global.criterion')}
                    setValue={setCriterion}
                  />
                </div>
                {criterion?.code === 'supplier' && (
                  <div className="w-full mb-4 md:w-1/3 pr-4">
                    <IdentityField
                      name="suppliers"
                      component={MultipleAutocompleteAdapter}
                      options={options}
                      initValue={inventory.suppliers}
                      label={t('global.suppliers')}
                      type="text"
                    />
                  </div>
                )}
                {criterion?.code === 'location' && (
                  <div className="w-full mb-4 md:w-1/3 pr-4">
                    <IdentityField
                      name="locations"
                      component={MultipleAutocompleteAdapter}
                      options={options}
                      initValue={inventory.locations}
                      label={t('global.locations')}
                      type="text"
                    />
                  </div>
                )}
                {criterion?.code === 'category' && (
                  <div className="w-full mb-4 md:w-1/3 pr-4">
                    <IdentityField
                      name="categories"
                      component={MultipleAutocompleteAdapter}
                      options={options}
                      initValue={inventory.categories}
                      label={t('global.categories')}
                      type="text"
                    />
                  </div>
                )}
              </>
            )}
            <div className="w-full my-2 pr-2">
              <IdentityField
                name="notes"
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
                initialValue={inventory.notes}
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default InventoryCheckForm;
