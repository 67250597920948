import { IFolder } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from '../BaseService';

class FMFolderService extends BaseService<IFolder> {
  constructor() {
    super('folders');
  }

  getFoldersByNameStartingWith = async (name: string) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/by-folder-name/`, {
      params: { name: name }
    });
    return res.data;
  };
}

export const fmFolderService = new FMFolderService();
