import { inventoryCheckLineService, inventoryCheckService } from 'services/api';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IInventoryCheck } from 'interfaces';
import { useEffect, useState } from 'react';
import InventoryCheckForm from '../InventoryCheckForm';
import toast from 'features/toast';
import { GridColDef } from '@mui/x-data-grid-premium';
import { InventoryCheckColumns } from 'components/common/enhanced/common-headers/inventory-check';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';

export const EditInventory = () => {
  const translationPrefix = 'pages.inventory-check.edit';
  const { t } = useTranslation();

  const { inventoryId } = useParams();
  const [inventory, setInventory] = useState<IInventoryCheck>();

  useEffect(() => {
    inventoryCheckService.get(inventoryId).then(setInventory);
  }, []);

  const updateInventory = async (values) => {
    inventoryCheckService.update(inventory.id, values).then((res) => {
      toast.success('Inventario aggiornato con successo');
      inventoryCheckService.get(inventoryId).then(setInventory);
    });
  };

  // const columns: GridColDef[] = InventoryCheckColumns();

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode="">
      <div className="flex flex-wrap">
        <div className="w-full p-4">
          <InventoryCheckForm inventory={inventory} onSubmit={updateInventory} />
        </div>
        {/* {inventory && (
          <EnhancedDataGrid
            columns={columns}
            service={inventoryCheckLineService}
            outerParams={{ inventory_check: inventory.id }}
          />
        )} */}
      </div>
    </TenantPage>
  );
};

export default EditInventory;
