import ReactFinalForm from 'components/form/ReactFinalForm';
import { IOption, IOptionFM } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import DocumentAutoComplete from '../../inventory/DocumentsG4/DocumentAutoComplete';
import { Field } from 'react-final-form';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';

interface AddDocumentFormParams {
  onSubmit: any;
  categorys: IOptionFM[];
  suppliers: IOption[];
  warehouses: IOption[];
}

function AddDocumentForm(params: AddDocumentFormParams) {
  const translationPrefix = 'pages.document.add';
  const { t } = useTranslation();

  const { onSubmit, categorys, suppliers, warehouses } = params;

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} formId="addNewDocument" hideControls>
        <div className="flex flex-col gap-5 w-full">
          <div className="flex gap-5 w-full">
            <IdentityField
              required
              component={TextFieldAdapter}
              name="name"
              label={t(`${translationPrefix}.name`)}
              type="text"
            />
            <IdentityField
              required
              component={DocumentAutoComplete}
              name="tipology"
              label={t(`${translationPrefix}.tipology`)}
              type="text"
              options={categorys}
            />
          </div>
          <div className="w-full">
            <IdentityField
              component={TextFieldAdapter}
              name="category"
              label={t(`${translationPrefix}.category`)}
              type="text"
            />
          </div>
          <div className="flex gap-5 w-full">
            <div className="w-1/2">
              <IdentityField
                component={AutocompleteAdapter}
                name="supplier"
                label={t('global.supplier')}
                type="text"
                options={suppliers}
              />
            </div>
            <div className="w-1/2">
              <IdentityField
                component={AutocompleteAdapter}
                name="warehouse"
                label={t('global.warehouse')}
                type="text"
                options={warehouses}
              />
            </div>
          </div>
          <div className="w-full ">
            <IdentityField
              component={TextFieldAdapter}
              name="description"
              label={t('global.description')}
              type="text"
              multiline
              rows={4}
            />
          </div>
          <div className="">
            <Field name="attachment" required>
              {({ input: { value, onChange, ...input } }) => (
                <input
                  type="file"
                  onChange={({ target }) => onChange(target.files)}
                  style={{ marginBottom: '16px' }}
                  {...input}
                />
              )}
            </Field>
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default AddDocumentForm;
